import * as fromUser from '../actions/user.action';
import { IClaims } from '../../interfaces';
import { UserDto } from '@bucke/ngx-api';

export interface UserState {
    loaded: boolean;
    loading: boolean;
    personalUserData: UserDto;
    claims: IClaims;
}

const initialState: UserState = {
    loaded: false,
    loading: false,
    personalUserData: undefined,
    claims: undefined,
};

export function userReducer(state: UserState = initialState, action: fromUser.UserActions): UserState {
    switch (action.type) {
        // Load
        case fromUser.LOAD_USER: {
            return {
                ...state,
                loaded: false,
                loading: true,
            };
        }

        case fromUser.LOAD_USER_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }

        case fromUser.LOAD_USER_SUCCESS: {
            let user = {};

            if (action.payload.personal === true) {
                user = {
                    personalUserData: action.payload.user,
                };
            } else {
                // TODO: Future use
            }

            return {
                ...state,
                ...user,
                loaded: true,
                loading: false,
            };
        }

        // Claims
        case fromUser.LOAD_USER_PROFILE: {
            return {
                ...state,
                loaded: false,
                loading: true,
            };
        }

        case fromUser.LOAD_USER_PROFILE_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }

        case fromUser.LOAD_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                claims: action.payload.claims,
                loaded: true,
                loading: false,
            };
        }

        default:
            break;
    }

    return state;
}

// Load
export const getLoaded = (state: UserState) => state.loaded;
export const getLoading = (state: UserState) => state.loading;

// Data
export const getPersonalUserData = (state: UserState) => state.personalUserData;
export const getClaims = (state: UserState) => state.claims;
