import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { delay, first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private internalActive = false;
    public status: BehaviorSubject<boolean> = new BehaviorSubject(this.internalActive);

    private minimumLifetime = 500;

    public get active(): boolean {
        return this.internalActive;
    }

    public set active(v: boolean) {
        if (v) {
            this.next(v);
        } else {
            this.status.pipe(first(), delay(this.minimumLifetime)).subscribe((prev) => {
                this.next(v);
            });
        }
    }

    public start(): void {
        this.active = true;
    }

    public stop(): void {
        this.active = false;
    }

    private next(v: boolean): void {
        this.internalActive = v;
        this.status.next(v);
    }
}
