import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InjectionToken, NgModule, PLATFORM_ID } from '@angular/core';
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

// Third party
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from './utils/http-loader';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsConfig, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { APP_CONFIG, AppConfig, AppConfiguration } from './app.config';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { AuthModule, Roles } from './auth';
import { SharedModule } from './shared';
import { LogModule, LogConfigurationBaseLoader, LogConfigurationLoader } from '@fp/ngx-log';
import {
    ApiModule as LegacyApiModule,
    ApiConfigurationBaseLoader as LegacyApiConfigurationBaseLoader,
    ConfigurationParameters as LegacyConfigurationParameters,
    ApiConfigurationLoader as LegacyApiConfigurationLoader,
} from '@bucke/ngx-api';
import { ApiModule, ConfigurationLoader, ConfigurationBaseLoader, ConfigurationParameters } from '@bucke/parent-portal';

import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ErrorHandlingConfigurationBaseLoader, ErrorHandlingConfigurationLoader } from './error-handling/error-handling.config';
import { HttpRequestFailed } from './core/store';
import { ErrorService } from './error-handling/services';

import { AppRoutingModule } from './app-routing.module';
import { BootstrapComponent } from './bootstrap/bootstrap.component';
import { CustomMissingTranslationHandler } from './utils/missing-translation-handler';
import { StoreRouterModule } from './store/store-router/store-router.module';
import { StoreTranslateModule } from './store/store-translate/store-translate.module';
import { WindowService } from './shared/window';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/locales/strings-', '.json');
}

export function logFactory(appConfiguration: AppConfiguration): LogConfigurationBaseLoader {
    // Allows you to set a custom. For example, based on the environment configuration.
    return new LogConfigurationLoader(appConfiguration.logLevel);
}

export function legacyApiFactory(platformId: InjectionToken<object>): LegacyApiConfigurationBaseLoader {
    const isBrowser = isPlatformBrowser(platformId);
    const config: LegacyConfigurationParameters = {};

    /*     if (isBrowser) {
        const location = window.location;

        config.basePath = `${location.origin}`;
    } */

    config.basePath = environment.configuration.apiLocation;

    // Allows you to set a custom. For example, based on the environment configuration.
    return new LegacyApiConfigurationLoader(config);
}

export function apiFactory(platformId: InjectionToken<object>): ConfigurationBaseLoader {
    const isBrowser = isPlatformBrowser(platformId);
    const config: ConfigurationParameters = {};

    /*     if (isBrowser) {
        const location = window.location;

        config.basePath = `${location.origin}`;
    } */

    config.basePath = environment.configuration.apiLocation;

    // Allows you to set a custom. For example, based on the environment configuration.
    return new ConfigurationLoader(config);
}

export function errorHandlingFactory(store: Store<any>, errorService: ErrorService): ErrorHandlingConfigurationBaseLoader {
    // Dispatches an action when we get a HttpErrorResponse (expect 401)
    const httpErrorResponseCallbackCallback = (error: HttpErrorResponse): void => {
        store.dispatch(
            HttpRequestFailed({
                response: errorService.serializeResponse(error),
            })
        );
    };

    return new ErrorHandlingConfigurationLoader(httpErrorResponseCallbackCallback);
}

export const legacyApiModule = LegacyApiModule.forRoot({
    configuration: {
        provide: LegacyApiConfigurationBaseLoader,
        deps: [PLATFORM_ID],
        useFactory: legacyApiFactory,
    },
});

export const apiModule = ApiModule.forRoot({
    configuration: {
        provide: ConfigurationBaseLoader,
        deps: [PLATFORM_ID],
        useFactory: apiFactory,
    },
});

@NgModule({
    declarations: [AppComponent, MainComponent, BootstrapComponent],
    imports: [
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // Third party
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
            useDefaultLang: false,
        }),

        // NGRX
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument(),
        StoreRouterModule,
        StoreTranslateModule,

        // Routing
        AppRoutingModule,

        // Custom
        ErrorHandlingModule.forRoot({
            configuration: {
                provide: ErrorHandlingConfigurationBaseLoader,
                useFactory: errorHandlingFactory,
                deps: [Store, ErrorService],
            },
        }),
        legacyApiModule,
        apiModule,
        AuthModule.forRoot({
            unauthorizedRedirect: ['/unauthorized'],
            authorizedRedirect: [
                {
                    path: ['unauthorized'],
                    roles: [Roles.Admin, Roles.Supplier, Roles.Participant, Roles.SchoolAdmin, Roles.CityAdmin],
                },
                {
                    // Used for parents, currently they don't have a role!
                    path: ['observer-panel', 'welcome'],
                    roles: [undefined],
                },
            ],
            unallowedRedirectTargets: ['/signin-oidc.html', '/'],
            afterLoginTargetKey: 'afterLoginTarget',
        }),
        SharedModule,

        LogModule.forRoot({
            configuration: {
                provide: LogConfigurationBaseLoader,
                useFactory: logFactory,
                deps: [AppConfiguration],
            },
        }),
    ],
    providers: [
        {
            provide: APP_CONFIG,
            useValue: AppConfig,
        },
        {
            provide: StoreDevtoolsConfig,
            deps: [AppConfiguration],
            useFactory: (appConfiguration: AppConfiguration) => ({
                logOnly: appConfiguration.environmentProduction,
                maxAge: 25,
            }),
        },
        WindowService,
    ],
    bootstrap: [BootstrapComponent],
})
export class AppModule {}
