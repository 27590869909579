import * as fromTranslate from '../actions/translate.action';

export interface TranslateState {
    defaultLanguage: string;
    currentLanguage: string;
    loaded: boolean;
    loading: boolean;
}

const initialState: TranslateState = {
    defaultLanguage: 'en',
    currentLanguage: 'en',
    loaded: false,
    loading: false,
};

export function translateReducer(state: TranslateState = initialState, action: fromTranslate.TranslateActions): TranslateState {
    switch (action.type) {
        case fromTranslate.SET_DEFAULT_LANGUAGE_SUCCESS: {
            return {
                ...state,
                defaultLanguage: action.payload,
                loaded: false,
                loading: true,
            };
        }

        case fromTranslate.SET_CURRENT_LANGUAGE_SUCCESS: {
            return {
                ...state,
                currentLanguage: action.payload,
                loaded: true,
                loading: false,
            };
        }

        case fromTranslate.SET_CURRENT_LANGUAGE_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }

        default:
            break;
    }

    return state;
}

export const getDefault = (state: TranslateState) => state.defaultLanguage;
export const getCurrentLanguage = (state: TranslateState) => state.currentLanguage;

export const getLoaded = (state: TranslateState) => state.loaded;
export const getLoading = (state: TranslateState) => state.loading;
