import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        children: [
            {
                // Uses dummy component to redirect, because redirect not working with empty-path top-level routes
                // Check https://github.com/angular/angular/issues/10726 for more info.
                path: '',
                component: MainComponent,
            },
            {
                path: 'observer-panel',
                loadChildren: () => import('./observer-panel/observer-panel.module').then((m) => m.ObserverPanelModule),
            },
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },
            {
                path: 'unauthorized',
                loadChildren: () => import('./unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
