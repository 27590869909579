import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

// Based on the Angular EmailValidator, but this one does support test@domain.com
// validation instead of test@domain even though it's the spec (https://github.com/angular/angular/issues/16544)
const EMAIL_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Directive({
    /* tslint:disable-next-line:directive-selector */
    selector: '[email][formControlName],[email][formControl],[email][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }],
})
export class EmailValidator implements Validator {
    // TODO(issue/24571): remove '!'.
    private enabled!: boolean;
    // TODO(issue/24571): remove '!'.
    private onChange!: () => void;

    static email(c: AbstractControl): ValidationErrors {
        if (isEmptyInputValue(c.value)) {
            return null; // don't validate empty values to allow optional controls
        }
        return EMAIL_REGEXP.test(c.value) ? null : { email: true };
    }

    @Input()
    set email(value: boolean | string) {
        this.enabled = value === '' || value === true || value === 'true';

        if (this.onChange) {
            this.onChange();
        }
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.enabled ? EmailValidator.email(c) : null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
