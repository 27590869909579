import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import * as promoActions from '../actions/promo-event.action';

import { PromoService } from '@bucke/ngx-api';
import { LogService } from '@fp/ngx-log';

@Injectable()
export class PromoEventEffect {
    @Effect() loadPromoEvents$: Observable<promoActions.PromoEventActions> = this.actions.pipe(
        ofType(promoActions.LOAD_PROMO_EVENTS),
        map((action: promoActions.LoadPromoEvents) => action.payload),
        exhaustMap((payload) => {
            return this.promoService.apiV1PromosGet(payload).pipe(
                map((res) => {
                    return new promoActions.LoadPromoEventsSuccess(res);
                }),
                catchError((err) => {
                    this.log.error(err);
                    return of(new promoActions.LoadPromoEventsFailure(err));
                })
            );
        })
    );

    constructor(private actions: Actions, private promoService: PromoService, private log: LogService) {}
}
