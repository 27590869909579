import { Action } from '@ngrx/store';
import { actionType } from '../../../utils';
import { UserDto } from '@bucke/ngx-api';

export const LOAD_USER = actionType('[User] Loading user');
export const LOAD_USER_FAILURE = actionType('[User] Could not load user');
export const LOAD_USER_SUCCESS = actionType('[User] Loaded user successfully');

export const LOAD_USER_PROFILE = actionType('[User] Loading user profile');
export const LOAD_USER_PROFILE_FAILURE = actionType('[User] Could not load user profile');
export const LOAD_USER_PROFILE_SUCCESS = actionType('[User] Loaded user profile successfully');

// Load - Single
export class LoadUser implements Action {
    readonly type = LOAD_USER;
    constructor(public payload: { id: string; personal?: boolean }) {}
}

export class LoadUserFailure implements Action {
    readonly type = LOAD_USER_FAILURE;
    constructor(public payload: any) {}
}

export class LoadUserSuccess implements Action {
    readonly type = LOAD_USER_SUCCESS;
    constructor(public payload: { user: UserDto; personal: boolean }) {}
}

// Claims
export class LoadUserProfile implements Action {
    readonly type = LOAD_USER_PROFILE;
    constructor() {}
}

export class LoadUserProfileFailure implements Action {
    readonly type = LOAD_USER_PROFILE_FAILURE;
    constructor(public payload: any) {}
}

export class LoadUserProfileSuccess implements Action {
    readonly type = LOAD_USER_PROFILE_SUCCESS;
    constructor(public payload: { claims: any }) {}
}

// action types
export type UserActions = LoadUser | LoadUserFailure | LoadUserSuccess | LoadUserProfile | LoadUserProfileFailure | LoadUserProfileSuccess;
