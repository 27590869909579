import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LogService } from '@fp/ngx-log';

import { filter } from 'rxjs/operators';

import { AppConfiguration } from '../../app.config';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private readonly logPrefix = '[Application Sights]';

    public appInsightsEnabled: boolean;
    public appInsights: ApplicationInsights;

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId,
        private readonly logService: LogService,
        private readonly appConfiguration: AppConfiguration
    ) {
        this.appInsightsEnabled = this.appConfiguration.appInsightsInstrumentationKey !== null;

        // No tracking serverside
        if (isPlatformServer(this.platformId) || !this.appInsightsEnabled) {
            return;
        }

        // Tap into the LogService, errors are thrown via the ErrorHandler
        this.logService
            .listener()
            .pipe(filter((log) => log !== undefined))
            .subscribe((log) => {
                if (log.type === 'error') {
                    this.appInsights.trackException({
                        exception: new Error(log.logArguments.toString()),
                        severityLevel: SeverityLevel.Critical,
                    });
                } else {
                    this.appInsights.trackEvent({ name: log.type }, log.logArguments);
                }
            });
    }

    initialize(): void {
        // No tracking serverside
        if (isPlatformServer(this.platformId) || !this.appInsightsEnabled) {
            return;
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.appConfiguration.appInsightsInstrumentationKey,
                enableDebug: this.appConfiguration.appInsightsEnableDebug,
                enableDebugExceptions: this.appConfiguration.appInsightsEnableDebugExceptions,
                enableAutoRouteTracking: this.appConfiguration.appInsightsEnableAutoRouteTracking,
            },
        });

        const configurationLogs = [
            `Instrumentation key ${this.appConfiguration.appInsightsInstrumentationKey}`,
            `Enable debug ${this.appConfiguration.appInsightsEnableDebug}`,
            `Enable debug exceptions ${this.appConfiguration.appInsightsEnableDebugExceptions}`,
            `Enable auto route tracking ${this.appConfiguration.appInsightsEnableAutoRouteTracking}`,
        ];

        configurationLogs.forEach((config) => {
            this.logService.debug(`${this.logPrefix}: ${config}`);
        });

        this.appInsights.loadAppInsights();
        this.logService.debug(`${this.logPrefix}: Load`);

        this.appInsights.trackPageView();
        this.logService.debug(`${this.logPrefix}: Track pageview`);

        return;
    }
}
