import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { tap, map } from 'rxjs/operators';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private translate: TranslateService) {}

    // Borrowed from https://stackoverflow.com/questions/35922071/warn-user-of-unsaved-changes-before-leaving-page
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        const canDeactivate = component.canDeactivate();

        // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355

        // if there are no pending changes, just allow deactivation; else confirm first

        if (canDeactivate instanceof Observable) {
            return canDeactivate.pipe(
                map((result) => {
                    return result === true ? true : confirm(this.translate.instant('PENDING_CHANGES'));
                })
            );
        } else {
            return canDeactivate ? true : confirm(this.translate.instant('PENDING_CHANGES'));
        }
    }
}
