import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { TranslateModule } from '@ngx-translate/core';

import * as Store from './store';
import * as Validator from './validators';
import * as Directive from './directives';
import * as Guard from './guards';

@NgModule({
    imports: [
        // Angular
        CommonModule,
        FormsModule,

        // Third party
        TranslateModule.forChild({
            useDefaultLang: false,
        }),
        NgxWebstorageModule.forRoot({
            prefix: 'bucke',
            caseSensitive: true,
        }),
        BsDropdownModule.forRoot(),

        // Custom
        StoreModule.forFeature('sharedModule', Store.reducers),
        EffectsModule.forFeature(Store.effects),
    ],
    declarations: [
        Validator.DecimalValidator,
        Validator.EAN13Validator,
        Validator.EmailValidator,
        Validator.PinCodeValidator,

        Directive.NumberInputDirective,
    ],
    providers: [Guard.PendingChangesGuard],
    exports: [
        Validator.DecimalValidator,
        Validator.EAN13Validator,
        Validator.EmailValidator,
        Validator.PinCodeValidator,

        Directive.NumberInputDirective,
    ],
})
export class SharedModule {}
