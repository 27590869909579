import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { LogService } from '@fp/ngx-log';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    protected readonly logPrefix: string = '[AuthInterceptor] ';

    constructor(private logService: LogService, private oauthService: OAuthService) {}

    private handleErrors(request: HttpRequest<any>, result: HttpEvent<any>): void {
        if (!(result instanceof HttpErrorResponse)) {
            return;
        }

        if (result.status === 401) {
            this.logService.info(this.logPrefix, 'Unauthenticated');
            this.oauthService.initImplicitFlow();

            return;
        }
    }

    /**
     * Intercepts all http requests
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                () => {},
                (result: HttpEvent<any>) => this.handleErrors(request, result)
            )
        );
    }
}
