import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as Component from './components';

const routes: Routes = [
    {
        path: 'signin-oidc.html',
        // Component governs where to go after logging in.
        component: Component.SigninComponent,
    },
    {
        path: 'signout-oidc.html',
        component: Component.SignoutComponent,
    },
    {
        path: 'role-redirect',
        component: Component.RedirectComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
