import { ActionReducerMap, createFeatureSelector, createSelector, select } from '@ngrx/store';

import * as fromCampaign from './campaign.reducer';
import * as fromPromoEvent from './promo-event.reducer';
import * as fromUser from './user.reducer';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

// Types all Stores into a single Module State
export interface ModuleState {
    campaignStore: fromCampaign.CampaignState;
    promoEventStore: fromPromoEvent.PromoEventState;
    userStore: fromUser.UserState;
}

// Groups all reducers
export const reducers: ActionReducerMap<ModuleState> = {
    campaignStore: fromCampaign.campaignReducer,
    promoEventStore: fromPromoEvent.promoEventReducer,
    userStore: fromUser.userReducer,
};

// Modules
export const getModuleState = createFeatureSelector<ModuleState>('sharedModule');

// Stores
export const getCampaignStore = createSelector(getModuleState, (state: ModuleState) => state.campaignStore);
export const getPromoEventStore = createSelector(getModuleState, (state: ModuleState) => state.promoEventStore);
export const getUserStore = createSelector(getModuleState, (state: ModuleState) => state.userStore);

// Campaign
export const getCampaignsLoaded = createSelector(getCampaignStore, fromCampaign.getLoaded);
export const getCampaignsLoading = createSelector(getCampaignStore, fromCampaign.getLoading);
export const getCampaignsData = createSelector(getCampaignStore, fromCampaign.getData);

// Promo event
export const getPromoEventsLoaded = createSelector(getPromoEventStore, fromPromoEvent.getLoaded);
export const getPromoEventsLoading = createSelector(getPromoEventStore, fromPromoEvent.getLoading);
export const getPromoEventsData = createSelector(getPromoEventStore, fromPromoEvent.getData);

// User
export const getPersonalUserLoaded = createSelector(getUserStore, fromUser.getLoaded);
export const getPersonalUserLoading = createSelector(getUserStore, fromUser.getLoading);
export const getPersonalUserData = createSelector(getUserStore, fromUser.getPersonalUserData);
export const getUserProfileClaims = createSelector(getUserStore, fromUser.getClaims);

export const personalUserData = () => {
    return pipe(
        select(getPersonalUserData),
        filter((data) => data !== undefined)
    );
};
