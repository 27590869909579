import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    /* tslint:disable-next-line:directive-selector */
    selector: '[pinCode]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PinCodeValidator, multi: true }],
})
export class PinCodeValidator implements Validator {
    static pinCode(c: AbstractControl): ValidationErrors {
        const pinCodeFormat: RegExp = /^\d{4}$/; // Contains 4 numbers
        const forbiddenPinCodes = ['0000', '1111', '1234', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999'];

        if (c.value !== null && pinCodeFormat.test(c.value) && !forbiddenPinCodes.includes(c.value.toString())) {
            return null;
        }

        return { pinCode: true };
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return PinCodeValidator.pinCode(c);
    }
}
