import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import * as promoActions from '../actions/campaign.action';

import { CampaignService } from '@bucke/ngx-api';
import { LogService } from '@fp/ngx-log';

@Injectable()
export class CampaignEffect {
    @Effect() loadCampaigns$: Observable<promoActions.CampaignActions> = this.actions.pipe(
        ofType(promoActions.LOAD_CAMPAIGNS),
        map((action: promoActions.LoadCampaigns) => action.payload),
        exhaustMap((payload) => {
            return this.campaignService.apiV1CampaignsGet(payload).pipe(
                map((res) => {
                    return new promoActions.LoadCampaignsSuccess(res);
                }),
                catchError((err) => {
                    this.log.error(err);
                    return of(new promoActions.LoadCampaignsFailure(err));
                })
            );
        })
    );

    constructor(private actions: Actions, private campaignService: CampaignService, private log: LogService) {}
}
