import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { LogService } from '@fp/ngx-log';
import { ActionCreator } from '@ngrx/store';

import { OperatorFunction, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SerializedHttpErrorResponse } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor(private logService: LogService) {}

    /**
     * Serializes HttpHeaders so it can be used in Action dispatches (due to strictActionSerializability)
     */
    private serializeHeaders(headers: HttpHeaders): { [key: string]: string | number } {
        const keys = headers.keys();
        const responseHeader = {};

        keys.forEach((key) => {
            responseHeader[key] = headers.get(key);
        });

        return responseHeader;
    }

    /**
     * Serialize HttpErrorResponse
     */
    serializeResponse(response: HttpErrorResponse): SerializedHttpErrorResponse {
        const { headers, status, statusText, url, ok, type } = response;

        return {
            headers: this.serializeHeaders(headers),
            status,
            statusText,
            url,
            ok,
            type,
        } as SerializedHttpErrorResponse;
    }

    /**
     * Handles an HttpErrorResponse and returns the given action.
     */
    handleHttpErrorResponse<T>(action: any): OperatorFunction<T, ActionCreator> {
        return (input$) =>
            input$.pipe(
                catchError((response: HttpErrorResponse) => {
                    const serializedResponse: SerializedHttpErrorResponse = this.serializeResponse(response);
                    const actionResponse = action({
                        response: serializedResponse,
                    });

                    this.logService.warn({
                        action: actionResponse,
                        response,
                    });

                    return of(actionResponse);
                })
            );
    }
}
