export const environment = {
    configuration: {
        environmentProduction: false,
        logLevel: 4,
        storeDevTools: true,

        languageAvailableLanguages: ['en', 'nl', 'fr', 'keys'],
        languageDefault: 'nl',

        appInsightsInstrumentationKey: null,
        appInsightsEnableDebug: false,
        appInsightsEnableDebugExceptions: false,
        appInsightsEnableAutoRouteTracking: false,

        googleAnalyticsKey: 'UA-137025309-3',

        apiLocation: 'https://api.testing.buck-e.be',
        idpIssuer: 'https://my.testing.buck-e.be',

        idpRedirectUri: '/signin-oidc.html',
        idpPostLogoutRedirectUri: '/signout-oidc.html',
        idpClientId: 'web',
        idpScope: 'openid profile api role',
        idpResponseType: 'id_token token',
        idpRequireHttps: true,
        idpShowDebugInformation: false,

        appWebsite: 'https://buck-e.be',
        appUrl: 'https://splash.testing.buck-e.be',
    },
};
