import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../services';
import { OAuthService } from 'angular-oauth2-oidc';
import { LogService } from '@fp/ngx-log';
import { LoadingService } from '../../../shared/loading/services';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit, OnDestroy {
    private redirect$: Subscription;

    constructor(
        private router: Router,
        private log: LogService,
        private loadingService: LoadingService,
        private authService: AuthService,
        private oauthService: OAuthService
    ) {}

    ngOnInit(): void {
        this.loadingService.start();

        // The sole purpose of this page is just to redirect the user based on the role to the correct page
        this.redirect$ = this.authService.redirectFromClaim().subscribe(
            (redirect) => {
                this.router.navigate(redirect.path);
            },
            (error) => {
                this.log.error(error);
                this.oauthService.initImplicitFlow();
            }
        );
    }

    ngOnDestroy(): void {
        this.loadingService.stop();

        if (this.redirect$ !== undefined) {
            this.redirect$.unsubscribe();
        }
    }
}
