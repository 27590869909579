import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';

import { Observable, of } from 'rxjs';
import { filter, map, tap, exhaustMap, catchError, first } from 'rxjs/operators';

import * as fromSharedStore from '../../shared/store';
import { LogService } from '@fp/ngx-log';
import { AuthService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class UserGuard implements CanActivate {
    constructor(
        private oauthService: OAuthService,
        private store: Store<fromSharedStore.ModuleState>,
        private log: LogService,
        private authService: AuthService
    ) {}

    canActivate(): Observable<boolean> {
        return this.authService.loadSharedDiscoveryDocument().pipe(
            exhaustMap(() => {
                if (!this.oauthService.hasValidAccessToken()) {
                    return of(false);
                }

                this.store.dispatch(new fromSharedStore.LoadUserProfile());

                // The "role" claim is only accessible through the endpoint
                return this.authService.loadUserProfile().pipe(
                    first(),
                    catchError((error) => {
                        this.log.error(error);

                        this.store.dispatch(new fromSharedStore.LoadUserProfileFailure(error));

                        return of(error);
                    })
                );
            }),
            tap((claims) => {
                this.store.dispatch(
                    new fromSharedStore.LoadUserProfileSuccess({
                        claims,
                    })
                );
            }),
            exhaustMap(() => {
                return this.store.select(fromSharedStore.getUserProfileClaims).pipe(
                    filter((claims) => claims !== undefined),
                    map(() => true)
                );
            })
        );
    }
}
