import { NgModule, ErrorHandler, APP_INITIALIZER, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LogModule } from '@fp/ngx-log';

import { AppInsightsErrorHandler } from './providers/app-insights-error-handler';
import { ErrorHandlingModuleConfig } from './interfaces/error-handling-module-config';
import { ErrorHandlingConfigurationBaseLoader, ErrorHandlingConfigurationFakeLoader } from './error-handling.config';

import * as Services from './services';
import * as Interceptors from './interceptors';

export function initializeConfigFactory(ApplicationInsightsService: Services.ApplicationInsightsService): () => void {
    return () => ApplicationInsightsService.initialize();
}

@NgModule({
    declarations: [],
    imports: [CommonModule, LogModule.forChild()],
    providers: [Services.ErrorService, Services.ApplicationInsightsService, Interceptors.HttpErrorInterceptor],
})
export class ErrorHandlingModule {
    constructor(@Optional() @SkipSelf() parentModule?: ErrorHandlingModule) {
        if (parentModule) {
            throw new Error('ErrorHandlingModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config: ErrorHandlingModuleConfig = {}): ModuleWithProviders<ErrorHandlingModule> {
        return {
            ngModule: ErrorHandlingModule,
            providers: [
                config.configuration || { provide: ErrorHandlingConfigurationBaseLoader, useClass: ErrorHandlingConfigurationFakeLoader },
                {
                    provide: APP_INITIALIZER,
                    useFactory: initializeConfigFactory,
                    deps: [Services.ApplicationInsightsService],
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: Interceptors.HttpErrorInterceptor,
                    multi: true,
                },
                {
                    provide: ErrorHandler,
                    useClass: AppInsightsErrorHandler,
                },
            ],
        };
    }
}
