import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class TranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

    private static removeEmptyString(translations: object): any {
        const keys = Object.keys(translations);
        const nonEmptyKeys = keys.filter((key) => !!translations[key]);
        const nonEmptyTranslations = {};

        /* tslint:disable-next-line:prefer-for-of */
        for (let index = 0; index < nonEmptyKeys.length; index++) {
            nonEmptyTranslations[nonEmptyKeys[index]] = translations[nonEmptyKeys[index]];
        }

        return nonEmptyTranslations;
    }

    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<object> {
        // Used to display translations 'keys' to aid in translation
        if (lang === 'keys') {
            return of({});
        }

        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(map((res) => TranslateHttpLoader.removeEmptyString(res)));
    }
}
