import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { exhaustMap, map, catchError, shareReplay } from 'rxjs/operators';

import * as userActions from '../actions/user.action';

import { UserService, UserDto } from '@bucke/ngx-api';
import { LogService } from '@fp/ngx-log';
import * as fromSharedStore from '../../../shared/store/reducers';

@Injectable()
export class UserEffect {
    @Effect() loadUser$: Observable<userActions.UserActions> = this.actions.pipe(
        ofType(userActions.LOAD_USER),
        map((action: userActions.LoadUser) => action.payload),
        exhaustMap((payload) => {
            // If user isn't personal always load fresh data
            if (payload.personal === true) {
                return this.checkPersonalUserData().pipe(
                    exhaustMap(() => {
                        return this.loadUser(payload).pipe(shareReplay(1));
                    })
                );
            } else {
                return this.loadUser(payload);
            }
        })
    );

    private checkPersonalUserData(): Observable<UserDto | undefined> {
        return this.store.select(fromSharedStore.getPersonalUserData);
    }

    private loadUser(payload: { id: string; personal?: boolean }): Observable<userActions.UserActions> {
        return this.userService.apiV1UsersIdGet(payload).pipe(
            map((res) => {
                return new userActions.LoadUserSuccess({
                    user: res,
                    personal: payload.personal !== undefined ? payload.personal : false,
                });
            }),
            catchError((err) => {
                this.log.error(err);
                return of(new userActions.LoadUserFailure(err));
            })
        );
    }

    constructor(
        private actions: Actions,
        private store: Store<fromSharedStore.ModuleState>,
        private userService: UserService,
        private log: LogService
    ) {}
}
