import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfiguration } from './app/app.config';
import { environment } from 'environments/environment';

if (environment.configuration.environmentProduction) {
    enableProdMode();
}

const customConfig = [{ provide: AppConfiguration, useValue: environment.configuration }];

platformBrowserDynamic(customConfig)
    .bootstrapModule(AppModule)
    .then(() => {
        /* if ('serviceWorker' in navigator && environment.configuration.environmentProduction) {
            navigator.serviceWorker.register('./ngsw-worker.js');
        } */
    })
    .catch((err) => console.error(err));
